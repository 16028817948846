import React, { forwardRef } from 'react';
import imageResource from '../../resources/images';
import stringResource from '../../resources/strings';
import LatestVideo from '../LatestVideo/LatestVideo';
import './About.scss';

const About = forwardRef((props, ref) => {
  return (
    <section className='aboutSection' ref={ref}>
      <div>
        <div className='background-image'>
          {/*          <pre className='content'>
            {stringResource.portfolio.bandDescription}
          </pre> */}
        </div>

        <div className='aboutSection__quote'>
          <p>
            {stringResource.portfolio.about} and the <i>rave</i> reviews are
            in...
          </p>
        </div>
        <img
          src={imageResource.home.emotionalcringe}
          alt='avatar'
          className='aboutSection__avatar__image'
        ></img>
      </div>
      <div className='aboutSection__container'>
        <div className='aboutSection__introduction'>
          <LatestVideo videoId={0} />
        </div>
        <div className='aboutSection__introduction'>
          <LatestVideo videoId={1} />
        </div>
        <div className='aboutSection__introduction'>
          <LatestVideo videoId={2} />
        </div>
        <div className='aboutSection__introduction'>
          <LatestVideo videoId={3} />
        </div>
      </div>
    </section>
  );
});

export default About;
