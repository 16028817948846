/**
 * Webiste to stringify lyrics
 * https://toolsaday.com/text-tools/json-stringify
 */

const songs = [
  {
    id: 1,
    name: 'the day i met you',
    lyric:
      "I've seen that place\nAnd I've lived her life\nAnd I know two or more\n\nStories come alive\nPutting casts aside\nThese tenements\nThey just grow\n\nThe day I met you \nIs the day I found my own\nNow's the time I'd show it all In-\n-securities they're flipping gone\nI'm the king and I'ma scream it loud\n\nThe day I met you \nIs the day I found my own\nNow's the time I'd show it all\nInsecurities they're flipping gone\nI'm the queen and I'ma show it all\n\nMatch box sized\nSurrounded by \nVultures\nWho want more\n\nShe's been ostracised\nNeglected child\nAbused\nNo more\n\nThe day I met you \nIs the day I found my own\nNow's the time I'd show it all\nInsecurities they're flipping gone\n",
  },
  {
    id: 2,
    name: 'hate wing',
    lyric:
      'One of those all lives matter guy\nI’m so fly, suits up with that khaki tie\nOnly lies, no matter how hard you try    \nYou could never live my life\nNot that I like\nTo write but I like to make it personal\n   \nOne of those social media girls\nAsinine but got them pearls \nI’m so great, I’m so lovely I’m so fake\nI’m so woke, patriot\nNot that I like\nTo fight but I like to point when it ain’t workable\n\nYour gods and your temple are  \nAnticlimactic \nNothing but a part of your feeble tactic\nSo you don’t have to pay no taxes    \nEvil inside, out like a fat cactus  \nThe fact is  \nYou are killing my friends with your pseudo political axes\n\nWhat do you know about making a living\nYou never worked hard for single penny\nLiving off of borrowed time & money  \nYour clouds got no silver lining\n\nProcreate\nWith a hideous associate\nTo compensate to suffocate and overstate \nImpostors like you brew hate\n\nI feel sick  \nI’m sick with the likes of you\n',
  },
  {
    id: 3,
    name: 'lonely together',
    lyric:
      'I’m thirty three\nAnd I still like to travel to my favourite place\nI’m on my knees\nAnd I still like to play with my furry mates\n\nI can’t imagine\nHow you dealt with the pain\nIt’s late in the evening\nAnd I can feel the stain\nHe wouldn’t say\nHe couldn’t stay \n\nSo we could  be\nLonely, lonely together\n\nWhat is life?\nAll I’ve learnt today is that it goes on\nI close my eyes\nWe sit tied together for our last ride\n\nI kept you hanging\nOn and off the gate\nPapa you loved her \nFor all those days \n\nHe wouldn’t say\nHe couldn’t stay \n\nSo we could be\nLonely, lonely together\n',
  },
  {
    id: 4,
    name: 'heal',
    lyric:
      'I never felt a part of them \nI never felt I was one of them \nI thought I’d hack my way out \nThese feelings \n\nBut pain and shame \nNever go away  \nYou fight it all \nEveryday\n\nPain and shame \nNever go away  \nYou fight it all \nEveryday\n\nThey’d never forgive me but I did\nAnd you know what I forgive myself \nI thought I’d hack my way out \nThese feeling\n\nBut pain and shame \nNever go away  \nYou fight it all \nEveryday\n\nPain and shame \nNever go away  \nYou fight it all \nEveryday\n\nShe made me \nShe made me \nLove you even more\n',
  },

  {
    id: 5,
    name: 'alive',
    lyric:
      "I was in an ocean\nI swam through time\nI laid there in silence\nEmpty and jaded inside\n\nThat's when he said\nSon\nI'm faster than light\nI'll teach you to fight\nWhen you're with me\nYou will feel\n\nAlive\nAlive\nLive\n\nI'll show you the moonlight\nWhen the sun sets in your\nHeart\nHeart\nLive\n\nTime stood still\nMemories failed\nShe spoke in violence\n",
  },
  {
    id: 6,
    name: 'clara bow',
    lyric:
      "Hello\nHow are you today?\nI've seen your face\nA thousand times before\n\nAnd so on\nAnd so on \n\nAnd if you want to stay all through the night\nWe wouldn't mind at all\nIt's your mistake\nAnd so on\nAnd so on\n\nWaves of applause\nIt's all we want\nWaves of applause\nIt's all we want\n\nAnd so on\nAnd so on\n\nWaves of applause\nIt's all we want\nWaves of applause\nIt's all we want\n",
  },
  {
    id: 7,
    name: 'introspect',
    lyric:
      'Are you turning \nInto someone \nYou are not\nAre you merely \nOne with yourself\nOr lost\n\nAll I know is I could have lost\nEverything we built in a moment\nDid you know you would harass\nAn innocent woman\n\nIs this what you want\nIs this what you are looking for\nIs this what you asked\n\nCause they will tell you to be careful\nTell you to be careful\nDon’t you wish away\n\nHappy ever afters\nHappy ever afters\nIs what you chase\nIs what you crave\nIs what you    taste\nIs what you\n\nSomething \nAbout chaos \nIs soothing \n\nMy family\nOf three\nEnds with me  \n\nIf you ever get to read this\nIt will probably sounds like a defence\nDr Jekyll and the Jackass\nAn accurate preface\n\nYour boring is my dream\nIt is what i ask for\nIt is what i want\n\nCause they will tell you to be careful\nTell you to be careful\nDon’t you wish away\n\nHappy ever afters\nHappy ever afters\nIs what you chase\nIs what you crave\nIs what you taste\nIs what you\n \nI \nCould just live a lie\nBlurry eyes in sight\nRed is the devil and I\nAye\n\nTalking to myself \nDelusion in my breath\nNo one will know right\nYou and your \nHigh\n',
  },
  {
    id: 8,
    name: 'pain and pleasure',
    lyric:
      "I wasn't this close when she came to me\nAnd just the way she spoke to me\nThrough her eyes\nI love her hair and I love her cheekbones\nThey were all red and I wasnae sure\nHer wee dance\n\nBlushing with pain and pleasure, she knew\nAll of my stories before I could choose\nTruth in her eyes\n\t\t\t\nShe made me see my demons\nAnd how they left me hurt\nYou’re like her she said\nYou’re like Kurt\nYou’re like her, she said,\nYou’re like Kurt\n\nShe made me smile and then put me in a mood\nShe's a right tease but all I want to\nis see her pleased\n\nEveryday they'd put me up to a test\nOne after the other, man take some rest\nHer wee face\n\nI turned my life around, my wings are cleansed\nTears drop from her eyes and I'm on the mend\nSteals my pain\n\nShe made me see my demons\nAnd how they left me hurt\nYou’re like Her she said\nYou’re like Kurt\nYou’re like her, she said,\nYou’re like Kurt\n\n\nShe's my twenty out of ten\nI miss her face and I'm in hell\nShe's my twenty out of ten\nI love her and I'm still in hell\n\nGo\nAway\nGo\nAway\n\nWay?\nWay\nWay?\n",
  },
  {
    id: 9,
    name: "you'll never know",
    lyric:
      'You’ll never know\n\nGot to be on fire\nOr must be a saint \nCould be just a liar\nMarching his parade \n\nYou’ll never know\nWhat better show \nYou said we’d grow old\nTogether I’m sold \n\nShe can light my fire\nOr bring in the rain \nShe runs an empire\nTakes away my pain\n\nYou’ll never know\nWhat better show \nYou said we’d grow old\nTogether I’m sold \n\n\n',
  },
  {
    id: 10,
    name: 'fur cough',
    lyric:
      'I know you see it\nDon’t mean you’re there\nI trust you feel it\nDon’t mean I care\n\nI\nTortured everything I loved but \nYou are\nObsessed with yourself you walk on eggshells \nCool cruel star\nShallow and snappy my words they’re jaggy\nSpew your hate \nPoint it at me and forget my name\n\nCause you’re cracked at the seam\nAren’t you\nHuh\n\nA sudden bolt \nFrom the blue\nI’ll wear a shield \nAnd disregard you \n\nTruth\nI won’t let you get to her now \nDon’t you \nLove to make a scene\n',
  },
  {
    id: 11,
    name: 'i guess',
    lyric:
      'I guess I am \nIn love with \nFalling in love \nWith you \n\nYou must go\nBut you hold me\nI’m falling in love\nWith you\n\nI see you\nAnd you see me\nFalling in love\nWith you\n\nI guess I’m \nIn love with \nFalling in love \nWith you\n',
  },
  {
    id: 12,
    name: 'wounded dogs',
    lyric:
      'A house of blues\nWhere the rain pours through\nI patch the gaps with canvas art\n\nEvery little room with a broken thing \nFill each hole before the skies turn grey\n \nWith the punctures in my heart\nI’m armed with a past  \nLike a wounded dog\nI drifted apart\n\nSee the notion of falling in love\nWith someone forsaken\nWas popularized by media\nAnd fiction taken\n\nIt’s a fallacy fallacy fallacy a fallacy huh\nMy love is just a memory a memoria\n\nNo one wants to fall in love \nWith a boy with a plea\nIn the depths of euphoria\nShall i set you free\n\nPlease have a seat \nOf course this marriage is toxic \nA simple love \nIs never enough\nA bit dicey\nBlimey \nYes I am toxic\nTry me\nAt least I keep it real \nSpicy\nDeranged boy\nWhy me\n\nI made my peace\nYou can carry on to be\nThe woke pretentious being \nThat you are\nAnd spare us our lives\nI am better than him\nSelf made\nLow grade\nPicture me\nNo shade\nPicture that\nAim straight\nLow hat\nDrat\nI digress\n\nWe are wounded dogs\nAnd I am a wounded dog\nWe are wounded dogs\nWe are we are\n',
  },
  {
    id: 13,
    name: 'untie',
    lyric:
      'Apologies\nDon’t you diagnose me\n\nPardon me\nNo praising evaluatively\n\nNot knowing\nthe question\nAnswers come \nso easily \nso easily\n\nHow altruistic \nAltruistic\nOf you\n\nHow altruistic \nAltruistic\nOf you\n\nUntie me now\nUntie me now\nI am my cure\nI’m done here\n\nUntie me now\nUntie me now\nI am my cure\nI’m done with you \n\nDon’t give in\nEven though you feel the void \nThe selfish gene\nIs an endless game\nWe were born to try\n\nEven if I try\nYou don’t have the time\nWe cannot be friends\nWe cannot be friends\n\nTime testifies\nMoonlight lit the sky \nWe can make amends\nWe can make amends\n\nHold on through the night\nThere’s no need to pry \nStorm may never end\nThis storm may never end \n\nYour holding on to tight\nThreads will untie\nThis will be the end \nThis will be the end ',
  },
].reverse();

export default songs;
